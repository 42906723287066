<template>
      <div>
        <CRow>
             <CCol sm="12" class="d-flex justify-content-end">
              <CButton
                shape="square"
                color="add"
                v-c-tooltip="{placement:'top-end', content: $t('label.ClientType')}"
                class="d-flex align-items-center"
                @click="showModalCreateTpClientPortActivity"
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{ $t('label.nuevo') }}</span>
              </CButton>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                column-filter
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items="formatedItems"
                :fields="fields"
                :loading="isLoading"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                :active-page="activePage"
                pagination
              >
                <template #loading>
                    <loading/>
                </template>
                <template #RowNumber="{ item }">
                  <td class="center-cell">
                    {{ item.RowNumber }}
                  </td>
                </template>
                <template #Label="{ item }">
                  <td class="text-center">
                    <div>
                      <c-img-extended 
                        :src="item.Label"
                        :key="item.RowNumber"
                        :error-options="{ width: 200, height: 200, fontSize: 40 }"
                        thumbnail
                        class="label-actividad"
                      />
                    </div>
                  </td>
                </template>
                <template #Status="{item}">
                  <td class="text-center">
                    <div>
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                    </div>
                  </td>
                </template>

                <template #Details="{item, index}">
                  <td class="text-center">
                      <CButton
                        shape="square"
                        color="edit"
                        size="sm"
                        v-c-tooltip="{
                          content: $t('label.edit')+' '+$t('label.ClientType'),
                          placement: 'top-end'
                        }"
                        @click="showModalEditTpClientPortActivity(item, index)"
                      >
                        <CIcon name="pencil" />
                      </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
        </CRow>

    <modalCreateTpClientPortActivity :modal.sync="modalAdd" @submited="handleSubmit"/>
    <modalEditTpClientPortActivity :modal.sync="modalEdit" :tpClientActivityData="tpClientportActivitySelected" @submited="handleSubmit"/>
</div>
</template>
<style>
    .icon{
        background-color: null; 
        border: 0px;
    }
</style>
<script>
import { DateFormater } from '@/_helpers/funciones';
import modalCreateTpClientPortActivity  from './modal-create-tpclient-port-activity';
import modalEditTpClientPortActivity from './modal-edit-tpclient-port-activity';
import GeneralMixin from '@/_mixins/general';

function  fields(){
  
  return [
  { key: 'RowNumber', label: '#', _classes: 'text-center', _style: 'width:3%; text-align:center', filter: false},
  { key: 'TpClientNameEs', label:this.$t('label.ClientType'), _classes: 'text-uppercase', _style:'width:18%' },
  { key: 'PortActivityNameEn', label:this.$t('label.portActivity')+" (EN)", _classes: 'text-uppercase', _style:'width:19%' },
  { key: 'PortActivityNameEs', label:this.$t('label.portActivity')+" (ES)", _classes: 'text-uppercase', _style:'width:19%' },
  { key: 'Ultimo', label: this.$t('label.user'), _classes: 'text-uppercase', _style:'width:14%' },
  { key: 'FormatedDate', label: this.$t('label.date'), _classes:'text-center', _style:'width:14%' },
  { key: 'Status', label: this.$t('label.status'), _classes: 'text-center', _style:'width:14%' },
  { key: 'Details', label: '', _style: 'width:8%; min-width:45px;', sorter: false, filter: false },
];
}
function data() {
  return {
    items: [],
    // fields: fields,
    tpClientportActivitySelected: {},
    activePage: 1,
    isLoading: false,
    modalAdd: false,
    modalEdit: false,
  };
}

//*****Funcion created() del ciclo.
function created() {
  this.getPortTpClientActivityList();
}

//*****Funciones de methods:
function getPortTpClientActivityList() {
  this.isLoading = true;

  this.$http.get('PortActivityTpClient-list', { filter: 'ALL' })
  .then(response => {
    this.items = response.data.data;

  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}

function showModalCreateTpClientPortActivity() {
 
  this.modalAdd = true;
}
function showModalEditTpClientPortActivity(item, index) {
  this.tpClientportActivitySelected = Object.assign({}, item);

  this.modalEdit = true;
}
function handleSubmit(){
  this.getPortTpClientActivityList();
}

//*****Funcones de computed:
function formatedItems() {

  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    Ultimo: item.TransaLogin?item.TransaLogin:'N/A',
    PortActivityNameEs: item.PortActivityNameEs? item.PortActivityNameEs: '',
    PortActivityNameEn: item.PortActivityNameEn? item.PortActivityNameEn: '',
    TpClientNameEs: item.TpClientNameEs? item.TpClientNameEs: '',
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}

export default {
  name: 'actividades',
  mixins: [GeneralMixin],
  components: { modalCreateTpClientPortActivity, modalEditTpClientPortActivity},
  data,
  created,
  methods: {
    getPortTpClientActivityList,
    showModalCreateTpClientPortActivity,
    showModalEditTpClientPortActivity,
    handleSubmit
  },
  computed: {
    formatedItems,
    fields
  }
}
</script>

<style scoped>
.label-actividad{
  width: 4em;
  height: 4em;
}
.center-cell {
  text-align: center;
  vertical-align: middle;
}
</style>