<template>
<CModalExtended
  :title="getTitle"
  color="dark"
  :close-on-backdrop="false"
  :show.sync="modalActive"
  class="modal-extended"
  size="lg"
  
>
<loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow>
      <CCol sm="12" lg="12" xl="12">
        <CCard class="card-simple">
          <CCardBody >
            <CRow  class="mb-4">
              
              <CCol sm="12" lg="12" xl="12">
                <CRow>
                  <CCol sm="12" lg="12" xl="12" class="pl-0">
                    <CSelect
                      v-model.trim="$v.actividadPortuaria.tpClientId.$model"
                      :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-7 col-xl-7'}"
                      :invalid-feedback="errorMessage($v.actividadPortuaria.tpClientId)"
                      :is-valid="hasError($v.actividadPortuaria.tpClientId)"
                      :label="$t('label.ClientType')"
                      addLabelClasses="required text-right"
                      :options="tpClientFormatted"
                    />
                  </CCol>
                  <CCol sm="12" lg="12" xl="12" class="pl-0">
                    <CSelect
                      v-model.trim="$v.actividadPortuaria.PortActivityId.$model"
                      :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-7 col-xl-7'}"
                      :invalid-feedback="errorMessage($v.actividadPortuaria.PortActivityId)"
                      :is-valid="hasError($v.actividadPortuaria.PortActivityId)"
                      :label="$t('label.portActivity')"
                      addLabelClasses="required text-right"
                      :options="activityListFormatted"
                    />
                  </CCol>
                  <CCol sm="12" lg="12" xl="12" class="pl-0">
                    <CSelect
                      :value.sync="actividadPortuaria.Status"
                      :is-valid="statusSelectColor"
                      :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-7 col-xl-7'}"
                      :label="$t('label.status')"
                      v-model.trim="actividadPortuaria.Status"
                      :options="statusOptions"
                      addLabelClasses="required text-right"
                    />
                  </CCol>
                </CRow>
              </CCol>
             
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>         
  </form>
       <template #footer>
      <CButton
        color="add"
        class="m-2"
        :disabled="isSubmit"
        @click="changeStatus()"
      >
        <CIcon name="checkAlt"/><span class="ml-1">{{ $t('button.accept') }}</span>
      </CButton>
      <CButton
        color="wipe"
        class="m-2"
        :disabled="isSubmit"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/><span class="ml-1">{{ $t('button.cancel') }}</span>
      </CButton>
    </template>
</CModalExtended>
</template>

<script>
import tpClientActividadValidations from '@/_validations/actividad-portuaria/tpClientActividadFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import ActividadPortuariaMixin from '@/_mixins/actividad-portuaria';
import ModalMixin from '@/_mixins/modal';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    isLoadingActivitys: false,
    isLoadingTpClient: false,
    activityList: [], 
    tpClientList: [], 
    actividadPortuaria: {
      PortActivityTpClientId: '',
      TpClientNameEs:'',
      PortActivityId: '',
      tpClientId:'',
      Status: 1,
      statusOrigin: 1,
    },
    loadingOverlay: false,
    titulo: '',
  
  }
}

//Methods
function submit() {

  try {
    this.isSubmit = true;
    this.loadingOverlay = true;
    this.$v.actividadPortuaria.$touch();

    if (this.$v.actividadPortuaria.$error) {
        this.loadingOverlay = false;
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
    }
  
    let PortActivityTpClientJson = {

      PortActivityTpClientId:this.actividadPortuaria.PortActivityTpClientId,
      TpClientId: this.actividadPortuaria.tpClientId,
      PortActivityId: this.actividadPortuaria.PortActivityId,
      Status:  this.actividadPortuaria.Status, 
    };

    this.$http.put('PortActivityTpClient-update', PortActivityTpClientJson, { root: 'PortActivityTpClientJson'} )
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: response.data.data[0].Response,
        type: "success"
      });
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.loadingOverlay = false;
    });
  } catch (e) {
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }

}

function toggle(newVal) {
  if(!newVal)
    this.resetForm();
  this.modalActive = newVal;
}

function resetForm() {
  this.actividadPortuaria.PortActivityTpClientId = '';
  this.actividadPortuaria.PortActivityId = '';
  this.actividadPortuaria.tpClientId = '';
  this.actividadPortuaria.Status = 1;
  this.actividadPortuaria.TpClientNameEs = '';
  this.actividadPortuaria.statusOrigin = 1;
  this.$v.$reset();
}


function statusSelectColor() {
  return (this.actividadPortuaria.Status === 1)?true:false;
}


function getTitle() {

  return this.titulo;
}

function getActivityPort() {
  this.isLoadingActivitys = true;

  this.$http.get("PortActivity-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.activityList = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingActivitys = false;
  });
}

  function changeStatus() {
    if(this.actividadPortuaria.statusOrigin !== this.actividadPortuaria.Status){              
      this.$swal
        .fire(this.alertProperties({
          text: `${this.$t('label.changeStatusQuestion')+' '} ${this.actividadPortuaria.TpClientNameEs}?`,
        }))     
        .then((result) => {
        if (result.isConfirmed) {
            this.submit();
        }
      })
    }else{
      this.submit();
    }
  }


function activityListFormatted(){

 let _lang = this.$i18n.locale;

  if(this.activityList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.activityList.map(function(e){
      chart.push({
        value: e.PortActivityId, 
        label: _lang=='en' ?  e.PortActivityNameEn : e.PortActivityNameEs,
      })    
    })
    return chart;
  }
}


function getTpClient() {

  this.isLoadingTpClient = true;

  this.$http.get("TpClient-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.tpClientList = response.data.data;
    
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingTpClient = false;
  });
}

function tpClientFormatted(){
  if(this.tpClientList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.tpClientList.map(function(e){
      chart.push({
        value: e.TpClientId, 
        label: e.TpClientName,
      })    
    })
    return chart;
  }
}

function setTpClientActivity(newClientActivityData) {

  this.actividadPortuaria.PortActivityTpClientId = newClientActivityData.PortActivityTpClientId;
  this.actividadPortuaria.tpClientId = newClientActivityData.TpClientId;
  this.actividadPortuaria.PortActivityId = newClientActivityData.PortActivityId;
  this.actividadPortuaria.Status = newClientActivityData.FgActPortActivityTpClient?1:0;
  this.actividadPortuaria.statusOrigin = newClientActivityData.FgActPortActivityTpClient ? 1 : 0;
  this.actividadPortuaria.TpClientNameEs = newClientActivityData.TpClientNameEs;
  this.titulo = this.$t('label.edit')+this.$t('label.ClientType')+': '+this.actividadPortuaria.TpClientNameEs;
  this.$v.actividadPortuaria.$touch();
}

export default {
  name: 'modal-edit-tpclient-port-activity',
  mixins: [ActividadPortuariaMixin, ModalMixin],
  props: {
    modal: Boolean,
    tpClientActivityData: {
      type: Object,
      default: () => { }
    }
  },
  components: {
  },
  data,
  validations: tpClientActividadValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    getActivityPort,
    getTpClient,
    statusSelectColor,
    changeStatus,
    setTpClientActivity,
  },
  computed: { 
        activityListFormatted,
        tpClientFormatted,
        getTitle,
   },
  watch: {
    modal: function(val) {
      this.toggle(val);
      this.getActivityPort();
      this.getTpClient();    
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);

    },
    tpClientActivityData: function(newClientActivityData) {
      this.setTpClientActivity(newClientActivityData);
    }
  }
}
</script>

<style scoped>
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}

.preview {
  height: 15em;
}
</style>